// extracted by mini-css-extract-plugin
export var ammountDiv = "shipping-policy-module--ammountDiv--yPpUJ";
export var ammountDiv1 = "shipping-policy-module--ammountDiv1--2HKRd";
export var businessDaysP = "shipping-policy-module--businessDaysP--U8Xqv";
export var call = "shipping-policy-module--call--cS-Uv";
export var callPhoneNumber = "shipping-policy-module--callPhoneNumber--ZfIos";
export var callPhoneNumberOrAlertU = "shipping-policy-module--callPhoneNumberOrAlertU--+Y81o";
export var caption = "shipping-policy-module--caption--kurqk";
export var caption2Div = "shipping-policy-module--caption2Div--X3CGs";
export var captionB4 = "shipping-policy-module--captionB4--fWvMY";
export var captionDiv = "shipping-policy-module--captionDiv--AvAe8";
export var displayB = "shipping-policy-module--displayB--+pmb5";
export var displayDiv = "shipping-policy-module--displayDiv--DxFj4";
export var displayDiv3 = "shipping-policy-module--displayDiv3--kRFjr";
export var docTemplateDiv = "shipping-policy-module--docTemplateDiv--eTdU+";
export var orderAmountP = "shipping-policy-module--orderAmountP---kssG";
export var pDiv = "shipping-policy-module--pDiv--3t5In";
export var pDiv1 = "shipping-policy-module--pDiv1--143O9";
export var pDiv14 = "shipping-policy-module--pDiv14--VrxOI";
export var pDiv2 = "shipping-policy-module--pDiv2--mvlwK";
export var pDiv20 = "shipping-policy-module--pDiv20--whzkk";
export var pDiv7 = "shipping-policy-module--pDiv7--sUHop";
export var privacyPolicyB = "shipping-policy-module--privacyPolicyB--kQfZ0";
export var shippingPolicyDiv = "shipping-policy-module--shippingPolicyDiv--IcaRi";
export var span = "shipping-policy-module--span--3vaP6";
export var subtitleDiv2 = "shipping-policy-module--subtitleDiv2--BreH4";
export var subtitleLargeDiv = "shipping-policy-module--subtitleLargeDiv--9bNFQ";
export var textColumnDiv = "shipping-policy-module--textColumnDiv--3UQFZ";
export var textbodyDiv = "shipping-policy-module--textbodyDiv--NWgw8";
export var textbodyDiv2 = "shipping-policy-module--textbodyDiv2--6o1aP";
export var textbodyDiv4 = "shipping-policy-module--textbodyDiv4--yBcfN";
export var textbodyDiv6 = "shipping-policy-module--textbodyDiv6--UlsNs";
export var titleDiv = "shipping-policy-module--titleDiv--jECkI";