import * as React from "react";
import { FunctionComponent } from "react";
import * as styles from "./shipping-policy.module.css";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const ShippingPolicy: FunctionComponent = () => {
  return (
    <Layout>
    <div className={styles.shippingPolicyDiv}>
      <div className={styles.docTemplateDiv}>
        <div className={styles.textColumnDiv}>
          <div className={styles.titleDiv}>
            <b className={styles.displayB}>UDS SUPPLY SHIPPING POLICY</b>
          </div>
          <div className={styles.pDiv}>
            <div className={styles.captionDiv}>
              Thank you for visiting and shopping at UDS Supply.
            </div>
          </div>
          <div className={styles.subtitleLargeDiv}>
            <div className={styles.displayDiv}>Domestic Shipping Policy</div>
          </div>
            <div className={styles.caption2Div}>Shipment processing time</div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                All orders are processed within 2–3 business days of receipt.
                Orders are not processed or shipped from Friday, 12 pm EST
                through Sunday, or holidays.
              </div>
            </div>
            <div className={styles.pDiv2}>
              <div className={styles.captionDiv}>
                If we are experiencing a high volume of orders, shipments may be
                delayed by a few days. If your shipment experiences a
                significant delay, we will contact you via email or phone.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <b>Shipping rates and delivery estimates</b>
              </div>
            </div>
            <div className={styles.pDiv2}>
              <div className={styles.captionDiv}>
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv2}>
            <div className={styles.ammountDiv1}>
              <div className={styles.pDiv1}>
                <b className={styles.captionDiv}>
                  <p className={styles.orderAmountP}>Order Amount</p>
                </b>
              </div>
              <div className={styles.pDiv1}>
                <b className={styles.captionDiv}>$0.00-250</b>
              </div>
              <div className={styles.pDiv7}>
                <b className={styles.captionDiv}>$250+</b>
              </div>
            </div>
            <div className={styles.ammountDiv}>
              <div className={styles.pDiv1}>
                <div className={styles.captionDiv}>
                  <p className={styles.orderAmountP}>
                    <b>Standard Ground Shipping</b>
                  </p>
                  <p className={styles.businessDaysP}>
                    <span>(1–7 business days)</span>
                  </p>
                </div>
              </div>
              <div className={styles.pDiv1}>
                <b className={styles.captionDiv}>$25.00</b>
              </div>
              <b className={styles.captionB4}>*Free</b>
            </div>
            <div className={styles.ammountDiv}>
              <div className={styles.pDiv1}>
                <div className={styles.captionDiv}>
                  <p className={styles.orderAmountP}>
                    <b>FedEx Ground</b>
                  </p>
                  <p className={styles.businessDaysP}>
                    <span>(3–7 business days)</span>
                  </p>
                </div>
              </div>
              <div className={styles.pDiv1}>
                <b className={styles.captionDiv}>$50.00</b>
              </div>
              <div className={styles.pDiv7}>
                <b className={styles.captionDiv}>*Free</b>
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <p className={styles.orderAmountP}>
                  UDS Supply ships to addresses within the US, US Territories,
                  and APO/FPO/DPO addresses.
                </p>
                <p className={styles.orderAmountP}>&nbsp;</p>
                <p className={styles.businessDaysP}>
                  <b>Shipment Confirmation and Order Tracking</b>
                </p>
              </div>
            </div>
            <div className={styles.pDiv14}>
              <div className={styles.captionDiv}>
                You will receive a Shipment Confirmation email with your
                tracking number once your order has shipped. The tracking number
                will be active within 24 hours.
              </div>
            </div>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <b>Customs, Duties, and Taxes</b>
              </div>
            </div>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                UDS Supply is not responsible for any customs and taxes applied
                to your order. All fees imposed during or after shipping are the
                customer’s responsibility (including tariffs, taxes, and other
                costs).
              </div>
            </div>
          </div>
          <div className={styles.pDiv}>
            <div className={styles.captionDiv}><b>Damages</b></div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                UDS Supply is not liable for any products damaged or lost during
                shipping. If you received your order damaged, please file a
                claim with the shipment carrier. Save all packaging materials
                and damaged goods before filing a claim.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>
              Incorrect Shipping Addresses and Refused Delivery
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <p className={styles.orderAmountP}>
                  We make every attempt to validate the shipping address
                  provided at checkout to ensure it’s recognized as a valid
                  address by the USPS. If we cannot validate the address, we
                  will try to contact the customer to provide an updated
                  address. If we cannot update the address, the order will be
                  canceled and refunded.
                </p>
                <p className={styles.orderAmountP}>&nbsp;</p>
                <p className={styles.businessDaysP}>
                  UDS Supply will not be held responsible if the customer
                  provides the wrong shipping address and we cannot recover the
                  package.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>
              Missing or Stolen Shipments
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <p className={styles.orderAmountP}>
                  If you didn’t receive your order, but the shipping carrier has
                  reported that it was delivered, please let us know as soon as
                  possible:
                </p>
                <p className={styles.orderAmountP}>&nbsp;</p>
                <ol className={styles.callPhoneNumberOrAlertU}>
                  <li className={styles.callPhoneNumber}>
                    Call (<a className={styles.call} href="tel:1888-207-7210">1888-207-7210</a>)
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Or alert us at (<a className={styles.call} href="mailto:info@udssupply.com">info@udssupply.com</a>)
                  </li>
                </ol>
                <p className={styles.orderAmountP}>&nbsp;</p>
                <p className={styles.businessDaysP}>
                  We will file a claim with the shipping carrier. Local law
                  enforcement will be involved. We will replace or refund your
                  order when the investigation is complete. Allow up to 21 days
                  for the investigation.
                </p>
              </div>
            </div>
          </div>
          {/* <div className={styles.textbodyDiv}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                These organizations may link to our home page, publications or
                other Website information so long as the link:
              </div>
            </div>
            <div className={styles.pDiv20}>
              <div className={styles.captionDiv}>
                <ul className={styles.callPhoneNumberOrAlertU}>
                  <li className={styles.callPhoneNumber}>
                    Not in any way deceptive.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Not falsely imply sponsorship, endorsement or approval of
                    the linking party and its products and/or services.
                  </li>
                  <li>
                    It fits within the context of the linking party’s site.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                We only approve link requests from these organizations if it is
                proven that
              </div>
            </div>
            <div className={styles.pDiv20}>
              <div className={styles.captionDiv}>
                <ul className={styles.callPhoneNumberOrAlertU}>
                  <li className={styles.callPhoneNumber}>
                    the link would not make us look unfavorably at ourselves or
                    our accredited businesses
                  </li>
                  <li className={styles.callPhoneNumber}>
                    the organization does not have any negative records with us
                  </li>
                  <li className={styles.callPhoneNumber}>
                    the benefit to us from the visibility of the hyperlink
                    compensates for the absence of Global Care Supply
                  </li>
                  <li>
                    the link is in the context of general resource information
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.pDiv2}>
              <div className={styles.captionDiv}>
                Global Care Supply’s logo or other artwork will not be allowed
                for usage if linking is absent, and the process is not in
                accordance with the license agreement.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Links to Other Websites</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                Our Service may contain links to third-party websites or
                services that are not owned or controlled by Global Care Supply.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                Global Care Supply has no control over and assumes no
                responsibility for any third-party websites or services'
                content, privacy policies, or practices. You further acknowledge
                and agree that Global Care Supply shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with the use of or
                reliance on any such content, goods, or services available on or
                through any such web sites or services.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party websites or services that
                you visit.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Termination</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                We may terminate or suspend your access immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach these Terms and Conditions.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                Upon termination, your right to use the Service will cease
                immediately. Note that it is our right to allow any user access
                to our website. This could mean revoking your key at any time
                without notice. If necessary, we will block your IP address to
                prevent further visits to our website and use of our Service.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Your Privacy</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <a className={styles.caption} href="/privacy-policy">
                <span>{`Please read `}</span>
                <b className={styles.privacyPolicyB}>
                  <span>Privacy Policy</span>
                  <span className={styles.span}>.</span>
                </b>
              </a>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Reservation of Rights</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                We reserve the right to remove all links to our website. As you
                read this document, you approve of executing the action upon our
                request immediately.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                We also reserve the right to change these terms and conditions,
                as well as its linking policies. If a revision is necessary, we
                will notify you of the changes at least 30 days prior to any new
                terms taking effect.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                By continuing to access or use Our Service after those revisions
                become effective, you agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or part, please stop
                using the website and the Service.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Disclaimer</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                To the maximum extent permitted under applicable law, Global
                Care Supply, on its own behalf and on behalf of its Affiliates
                and its and their respective licensors and service providers,
                expressly disclaims all warranties, whether express, implied,
                statutory or otherwise, with respect to the Service, including
                all implied warranties of merchantability, fitness for a
                particular purpose, title and non-infringement, and warranties
                that may arise out of the course of dealing, course of
                performance, usage or trade practice.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                Without limiting the preceding, neither Global Care Supply nor
                any of the company's providers makes any representation or
                warranty of any kind, express or implied:
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <ul className={styles.callPhoneNumberOrAlertU}>
                  <li className={styles.callPhoneNumber}>
                    As to the operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon
                  </li>
                  <li className={styles.callPhoneNumber}>
                    That the Service will be uninterrupted or error-free
                  </li>
                  <li className={styles.callPhoneNumber}>
                    As to the accuracy, reliability, or currency of any
                    information or content provided through the Service
                  </li>
                  <li>
                    The Service, its servers, the content, or e-mails sent from
                    or on behalf of the Company are free of viruses, scripts,
                    trojan horses, worms, malware, timebombs or other harmful
                    components.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Consent</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                You hereby consent to our Terms and Conditions of Use by using
                our website.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv2}>
            <div className={styles.displayDiv3}>Contact Us</div>
          </div>
          <div className={styles.textbodyDiv4}>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                <span>
                  <span>
                    If you have any questions about these Terms and Conditions,
                    you can
                  </span>
                  <b className={styles.privacyPolicyB}>{` `}</b>
                </span>
                <b className={styles.privacyPolicyB}>
                  <span><a href="/#contactSectionContainer">contact us</a></span>
                  <span className={styles.span}>.</span>
                </b>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ShippingPolicy;

export const Head = () => (
  <SEO title="UDS Supply | Shipping Policy" description="Shop" />
)